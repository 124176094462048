<template>
  <v-card class="bordded">
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Saldo</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="6"
          md="15"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="success"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiTrendingUp }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <h3 class="text-xl font-weight-semibold">
              R$ {{ $money(String(availableLimit)) }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiTrendingUp } from '@mdi/js'

export default {
  props: {
    dados: {
      type: Array,
      default: () => [],
    },
    availableLimit: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {

      // icons
      icons: { mdiTrendingUp },
    }
  },
}
</script>
