<template>
  <div>
    <p
      v-if="services.length > 0"
      class="text-2xl"
    >
      Saldo
    </p>

    <v-divider
      v-if="services.length > 0"
      class="mb-5"
    />

    <v-row>
      <v-col
        v-if="dados.length"
        cols="12"
        sm="12"
      >
        <wallet-extract-statistics-card :dados="dados"></wallet-extract-statistics-card>
      </v-col>
      <v-col
        v-if="availableLimit"
        cols="12"
        sm="12"
      >
        <wallet-extract-balance-card :available-limit="availableLimit"></wallet-extract-balance-card>
      </v-col>
    </v-row>

    <p class="text-2xl mt-5">
      Movimentos
    </p>

    <v-divider class="mb-5" />

    <v-card class="mt-5">
      <v-card-text class="pa-3">
        <v-row>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <date
              v-model="filterYearMonth"
              :type="'month'"
              outlined
              hide-details
              dense
              label="Mês Movimento"
              readonly
              @input="getExtract"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-5">
      <v-data-table
        :headers="headers"
        :items="movements"
        item-key="id"
        dense
        class="table-rounded"
        disable-sort
      >
        <template #[`item.action`]="{ item }">
          <v-btn
            color="info"
            dark
            text
            title="Visualizar"
            x-small
            @click="setExtractDetail(item)"
          >
            <v-icon>{{ icons.mdiEye }}</v-icon>
          </v-btn>
        </template>
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <template #[`item.type`]="{item}">
          {{ item.type === 'campaign' ? 'Campanha' : "Recarga" }}
        </template>
        <template #[`item.createdAt`]="{ item }">
          {{ $day(item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
        </template>
        <template #[`item.value`]="{ item }">
          <span :class="resolveErrorBalance(item.value)">
            {{ $money(item.value, 2) }}
          </span>
        </template>
        <template #[`item.servicePlanPrice`]="{ item }">
          {{ $money(item.servicePlanPrice, 2) }}
        </template>
      </v-data-table>
    </v-card>

    <dialog-form
      :status="dialogExtractDetail"
      :title="`Movimento #${extractDetail.id}`"
      :width="1200"
      :hide-actions="true"
      @close="dialogExtractDetail = !dialogExtractDetail"
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            :value="$day(extractDetail.createdAt).format('DD/MM/YYYY HH:mm:ss')"
            disabled
            outlined
            hide-details
            dense
            label="Data"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            :value="$money(extractDetail.value, 2)"
            disabled
            outlined
            dense
            hide-details
            prefix="R$"
            label="Valor (R$)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersDetail"
            :items="extractDetail.walletMovements"
            item-key="id"
            class="table-rounded"
            disable-sort
            dense
            hide-default-footer
          >
            <template #[`item.createdAt`]="{ item }">
              {{ $day(item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
            </template>
            <template #[`item.value`]="{ item }">
              {{ $money(item.value, 2) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </dialog-form>
  </div>
</template>

<script>
import {
  mdiEye,
  mdiFileDocument,
  mdiMessageTextOutline,
  mdiPhonePlusOutline,
} from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { ref } from '@vue/composition-api'

import WalletExtractStatisticsCard from '@/components/wallet-extract-statistics-card/WalletExtractStatisticsCard.vue'
import WalletExtractBalanceCard from '@/components/wallet-extract-balance-card/WalletExtractBalanceCard.vue'

export default {
  components: {
    WalletExtractStatisticsCard,
    WalletExtractBalanceCard,
  },
  computed: {
    ...mapState('customerTaxes', ['rows']),
    dados() {
      return this.rows.map(item => {
        const service = this.services.find(i => i.id === item.serviceId)

        return {
          title: service ? service.name : '',
          total: item.unitaryValue,
        }
      })
    },
  },
  setup() {
    const filterYearMonth = ref(null)
    const extractDetail = ref({})
    const dialogExtractDetail = ref(false)
    const total = ref([])
    const movements = ref([])
    const services = ref([])
    const availableLimit = ref(null)

    const resolveErrorBalance = value => {
      if (value <= 0) return 'error--text font-weight-bold'

      return 'success--text'
    }

    return {
      filterYearMonth,
      extractDetail,
      dialogExtractDetail,
      services,
      resolveErrorBalance,
      icons: {
        mdiEye,
        mdiFileDocument,
        mdiMessageTextOutline,
        mdiPhonePlusOutline,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'Transação', value: 'id' },
        { text: 'Descrição', value: 'walletMovements[0].description' },
        { text: 'Serviço', value: 'type' },
        { text: 'Data', value: 'createdAt' },
        { text: 'Valor (R$)', value: 'value', align: 'right' },
      ],
      headersDetail: [
        { text: 'Código', value: 'id' },
        { text: 'Data', value: 'createdAt' },
        { text: 'Descrição', value: 'description' },
        { text: 'Valor (R$)', value: 'value', align: 'right' },
      ],
      movements,
      total,
      availableLimit,
    }
  },

  async created() {
    await this.findServices()
    await this.getExtract()
    await this.findCustomerTaxes({ userId: this.filterUser })
  },

  methods: {
    ...mapActions('customerTaxes', {
      findCustomerTaxes: 'find',
    }),
    ...mapActions('services', {
      fetchServices: 'find',
    }),
    ...mapActions('walletExtract', {
      fechWalletExtract: 'find',
      createMovement: 'create',
    }),

    setExtractDetail(item) {
      this.extractDetail = item
      this.dialogExtractDetail = true
    },

    async findServices() {
      this.services = (await this.fetchServices()).map(service => ({
        ...service,
        total: '0 envio',
      }))
    },

    async getExtract() {
      const { total, wallets } = await this.fechWalletExtract({
        yearMonth: this.filterYearMonth
          ? this.$day(this.filterYearMonth, 'MM/YYYY').format('YYYY-MM')
          : undefined,
      })

      this.availableLimit = total.availableLimit

      this.movements = wallets
    },
  },
}
</script>
