<template>
  <v-card class="bordded">
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Tarifa dos Serviços</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          v-for="(data, index) in dados"
          :key="index"
          cols="6"
          sm="12"
          md="4"
          lg="3"
          xlg="auto"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="info"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              R$ {{ $money(data.total, 4) }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiLabelOutline,
} from '@mdi/js'

export default {
  props: {
    dados: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {

      // icons
      icons: {
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
